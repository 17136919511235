
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { FaTimes, FaPlane } from "react-icons/fa";
import airportData from "../data/airport";
import {
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
} from "@material-ui/core";
import {
  FormGroup,
} from "reactstrap";
import './City.css'

function City({ type, code, setCode, Page, from_city, to_city, IsBarkAir }) {
  const { t } = useTranslation();
  const [cityCountry, setCityCountry] = useState("");
  const [filterdata, setFilterData] = useState([]);

  // Conditional dataset based on Page value
  const customAirportData = [
    {
      "city": "New York, Teterboro NYC",
      "country": "United States",
      "IATA": "TEB",
      "icao_code": "KTEB"
    },
    {
      "city": "Los Angeles, Van Nuys",
      "country": "United States",
      "IATA": "VNY",
      "icao_code": "KVNY"
    },
    {
      "city": "London",
      "country": "United Kingdom",
      "IATA": "STN",
      "icao_code": "EGSS"
    },
    {
      "city": "Paris",
      "country": "France",
      "IATA": "LBG",
      "icao_code": "LFPB"
    }
  ];


  // Use different airport data based on the Page value
  const airportSuggestionRef = useRef(
    (IsBarkAir ? customAirportData : airportData).map((item) => ({
      label: `${item.city}: ${item.IATA}`,
      value: item.IATA,
      country: item.country,
    }))
  );
  const options = airportSuggestionRef.current;

  useEffect(() => {
    if (IsBarkAir) {
      if (type === "from") {
        // Show all airports for departure
        setFilterData(options);
      } else {
        // For destination ('to' field):
        if (from_city === "TEB" && type !== "from") {
          // If departing from TEB, show all airports except TEB
          setFilterData(options.filter(item => item.value !== "TEB"));
        } else {
          // If departing from anywhere else, only show TEB
          setFilterData(options.filter(item => item.value === "TEB"));
        }
      }
    }
  }, [type, from_city, to_city]);


  useEffect(() => {
    setFilterData(options);
  }, [IsBarkAir, options]);

  useEffect(() => {
    if (code) {
      const airport = (IsBarkAir ? customAirportData : airportData).find(
        (item) => item.value === code || item.IATA === code
      );
      setCityCountry(
        airport ? `${airport.value || airport.IATA}-${airport.label || airport.city}, ${airport.country}` : ""
      );
    } else {
      setCityCountry("");
    }
  }, [code, Page]);

  const onClickInput = () => {
    setCode("");
  };

  const onTextChange = (event) => {
    setCityCountry(event.target.value);
    const filterdata = options.filter(
      (opt) =>
        opt.label.toLowerCase().includes(event.target.value.toLowerCase()) ||
        opt.value.toLowerCase().includes(event.target.value.toLowerCase()) ||
        opt.country.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilterData(filterdata);
  }

  const SelectOption = (event) => {
    setCode(event.target.attributes.getNamedItem("data-value").value);
  };

  return (
    <div>
      <FormGroup className="mb-3">
        <FormControl fullWidth>
          <InputLabel htmlFor={"city_" + type}>
            <span style={{ color: "#3b5e75", fontWeight: "900", fontSize: "20px" }}>
              {type === "from" ? "From" : "To"}
            </span>
          </InputLabel>
          <Input
            id={"city_" + type}
            value={cityCountry}
            onChange={onTextChange}
            onClick={onClickInput}
            placeholder="Anywhere"
            autoComplete="off"
            startAdornment={
              <InputAdornment position="start">
                {type === "from" ? (
                  <img
                    alt="..."
                    style={{ height: "15px", width: "22.5px" }}
                    src={
                      require("../assets/images/icons/plane-take-off.png")
                        .default
                    }
                  />
                ) : (
                  <img
                    alt="..."
                    style={{ height: "18px", width: "22.5px" }}
                    src={
                      require("../assets/images/icons/plane-landing.png")
                        .default
                    }
                  />
                )}
              </InputAdornment>
            }
          />
        </FormControl>
        {code !== null && type === "to" && Page !== "Book" && (
          <FaTimes
            style={{
              position: "absolute",
              bottom: "20px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={(e) => setCode(null)}
          />
        )}
        {code === "" && (
          <div className="Dropdown">
            <ul>
              {filterdata &&
                filterdata.map((item) => {
                  return (
                    <li
                      key={item.label}
                      data-value={item.value}
                      onClick={SelectOption}
                    >
                      {item.label}
                      {/* <div>
                        <div>
                          <span className="h3" data-value={item.value}>
                            {item.label}
                          </span>
                          <span
                            className="h4"
                            style={{ float: "right" }}
                            data-value={item.value}
                          >
                            {item.value}
                          </span>
                        </div>
                      </div> */}
                    </li>
                  );
                })}
              {filterdata.length < 1 && <li>No Data Found</li>}
            </ul>
          </div>
        )}
      </FormGroup>
    </div>
  );
}

export default City;
