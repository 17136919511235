import dummyUser from "../assets/images/login.png";
import "antd/dist/antd.css";
import "./chat.css";
import { Input, Layout, Modal, Button, Space, Badge } from "antd";
import { MdOutlineSearch, MdArrowBack, MdGroups } from "react-icons/md";
import {
  SendOutlined,
  VerticalRightOutlined,
  VerticalLeftOutlined, CloseOutlined
} from "@ant-design/icons";
import { MdMarkChatUnread } from "react-icons/md";

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { dateDisplay } from "../utils/dateConverter";
import { RiCheckDoubleFill } from "react-icons/ri";
import { apiRoutes } from "../constant/chat-api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Header, Sider, Content } = Layout;
const Chat = () => {
  const REACT_APP_SERVICE_URL = process.env.REACT_APP_SERVICE_URL;
  const [collapsed, setCollapsed] = useState(false);
  const [value, setValue] = useState("");
  const [userChatData, setUserChatData] = useState([]);
  const [userMessage, setUserMessage] = useState([]);
  const [inputMessage, setInputMessage] = useState("");

  const [activeUserId, setActiveUserId] = useState(null);
  const [existingConversationId, setExistingConversationId] = useState(null);
  const [activeConversationId, setActiveConversationId] = useState(false)
  const ModalValue = localStorage.getItem("ModalData")
  const [countfortimeinterval, setCountfortimeinterval] = useState(0)
  const [modalInput, setModalInput] = useState("")
  const ConvertModalValue = JSON.parse(ModalValue)
  const [namepic, setNamePic] = useState({ profilePicPath: "", userName: "" });
  const LoggedUserName = JSON.parse(localStorage.getItem("auth")).name;
  const userId = JSON.parse(localStorage.getItem("auth")).userid;
  const [isEnterPressed, setIsEnterPressed] = useState(false)
  const [is_group, setIs_group] = useState(false)
  {/* model for createConversation */ }
  const [visible, setVisible] = useState(false);
  {/* model for createConversation */ }

  const history = useHistory()

  const messagesRef = useRef(null);

  const pathName = window.location.pathname;
  function extractIdFromUrl(url) {

    const idRegex = /\/chat\/(\d+)/;

    const match = url.match(idRegex);

    if (match && match[1]) {
      return match[1];
    } else {
      return false;
    }
  }
  const urlId = extractIdFromUrl(pathName);

  const fetchData = async () => {
    try {
      const chat = await axios.get(
        `${REACT_APP_SERVICE_URL}/${apiRoutes.listConversations}/${userId}`
      );
      if (countfortimeinterval === 0) {
        setNamePic({ profilePicPath: chat.data.data[0].profile_pic_path, userName: chat.data.data[0].user_name })
        setIs_group(chat.data.data[0].is_group)
        if (ConvertModalValue?.is_group) {
          setCollapsed(true)
          const { profile_pic_path: profilePicPath, group_name: userName } = ConvertModalValue;
          setNamePic({ profilePicPath, userName });
        } else {

          if (ConvertModalValue) {
            setIs_group(false)
            setNamePic({
              profilePicPath: ConvertModalValue.profile_pic_path,
              userName: ConvertModalValue.name
            })
          }
        }
      }
      setUserChatData(chat.data.data);

    } catch (error) {
      console.error(error);
    }
  };

  const byDefaultConversationId = userChatData.length > 0 ? userChatData[0].conversation_id : "";

  useEffect(() => {
    // setCollapsed(true)
    fetchData();
    if (activeUserId) {
      autoUpdateChat(activeUserId)
    }
    const intervalId = setInterval(() => {
      setCountfortimeinterval(prevCounter => prevCounter + 1);
    }, 5000);

    return () => {
      clearInterval(intervalId)
    }
  }, [countfortimeinterval]);

  useEffect(() => {
    if (!existingConversationId) {
      if (ConvertModalValue?.is_group) {
        setIs_group(true)
        openFirstChat(ConvertModalValue.conversation_id)
        setActiveUserId(ConvertModalValue.conversation_id)
      } else {
        openFirstChat(byDefaultConversationId)
        setActiveUserId(byDefaultConversationId)
      }
    }
    return (() => {
      if (ConvertModalValue?.is_group) {
        localStorage.removeItem("ModalData")
      }
    })
  }, [byDefaultConversationId])
  // enable to open first chat 


  useEffect(() => {
    if (urlId) {
      setCollapsed(true)

      if (localStorage.getItem("ModalData")) {
        openFirstChat(existingConversationId)
        // setActiveUserId(existingConversationId);
        setExistingConversationId(null);
        if (ConvertModalValue.to_city === undefined) {
          setInputMessage("");
        } else {
          if (ConvertModalValue.is_posted) {
            const preMessage = `Hi ${ConvertModalValue.name}, I am interested in the seats you listed for your flight to ${ConvertModalValue.to_city} on ${ConvertModalValue.dep_date}`
            setInputMessage(preMessage)
          }
          else {
            const preMessage = `Hi ${ConvertModalValue.name}, your flight interest from ${ConvertModalValue.from_city} to ${ConvertModalValue.to_city} on ${ConvertModalValue.dep_date} may work for me too. Let's connect?`
            setInputMessage(preMessage)
          }
        }
      } else {
        setInputMessage("")
      }
      CheckConversationExist(urlId)
    }
    return (() => {
      localStorage.removeItem("ModalData")
    })
  }, [urlId]);


  //  handleCreateConversation for create new conversation Id

  const handleCreateConversation = async () => {
    setVisible(false)
    try {


      const CreateConversation = {
        user1_id: userId,
        user2_id: Number(urlId),
        initial_message_text: modalInput,

      };


      const response = await axios.post(
        `${REACT_APP_SERVICE_URL}/createConversation`,
        CreateConversation
      );
      if (response.status === 200) {
        const newConversationId = response.data.data[0].NewConversationId
        await UserMessageById({
          user_id: activeUserId,
          user_name: ConvertModalValue.name,
          profile_pic_path: ConvertModalValue.profile_pic_path,
          conversation_id: newConversationId,
        });
        await fetchData()
        localStorage.removeItem("ModalData")
        setInputMessage("")


        window.location.href = "/chat";
      } else {
        console.log("Request failed with status:", response.status);
      }


    } catch (error) {
      console.log(error);
    }
  };

  //  Check Conversation Exist 
  function CheckConversationExist(urlId) {

    try {
      const checkUserId = {
        user1_id: Number(urlId),
        user2_id: userId,
      };

      axios.post(`${REACT_APP_SERVICE_URL}/checkConversationExist`, checkUserId)
        .then((res) => {
          if (res.data.data === true) {

            const existConversationId = res.data.conversation_id
            setExistingConversationId(existConversationId);
            userMessageExist(existConversationId)
            if (urlId) {
              try {
                const jsonBody = { userid: urlId };
                axios.post(`${REACT_APP_SERVICE_URL}/GetUserDetails`, jsonBody)
                  .then((response) => {
                    setIs_group(false)
                    setNamePic({ profilePicPath: response.data[0].profile_pic_path, userName: response.data[0].name })
                  }).catch((error) => {
                    console.log(error);
                  })
              } catch (error) {
                console.log(error);
              }
            }

            window.history.replaceState({}, document.title, "/chat");




          } else {
            if (ConvertModalValue) {
              const preMessage = `Hi ${ConvertModalValue.name}, I am interested in the seats you listed for your flight to ${ConvertModalValue.to_city} on ${ConvertModalValue.dep_date}`
              setModalInput(preMessage)
              setVisible(true)
            } else {
              setInputMessage("")
              if (urlId) {
                try {
                  const jsonBody = { userid: urlId };
                  axios.post(`${REACT_APP_SERVICE_URL}/GetUserDetails`, jsonBody)
                    .then((response) => {
                      if (response.data.length === 0) {
                        window.history.replaceState({}, document.title, "/chat");

                      } else {
                        setNamePic({ profilePicPath: response.data[0].profile_pic_path, userName: response.data[0].name })
                        setVisible(true)
                      }
                    }).catch((error) => {
                      console.log(error);
                    })
                } catch (error) {
                  console.log(error);
                }
              }
            }

          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  }


  //   redirect user to exist chat 

  const userMessageExist = async (existConversationId) => {
    // setNamePic({
    //   userName: ConvertModalValue.name,
    //   profilePicPath: ConvertModalValue.profile_pic_path,
    // });
    try {
      setActiveUserId(existConversationId);
      const messageData = await axios.get(
        `${REACT_APP_SERVICE_URL}/${apiRoutes.listMessage}/${existConversationId}/${userId}`
      );
      setUserMessage(messageData.data.data);

    } catch (error) {
      console.error(error);
    }
  }

  //  redirect to chat of user 
  const filterUserChatData = (item, filterValue) => {
    const normalizedFilter = filterValue.toLowerCase();
    return (
      item.user_name?.toLowerCase().includes(normalizedFilter) ||
      item.last_message?.toLowerCase().includes(normalizedFilter)
    );
  };

  const searchData = userChatData.filter((item) =>
    filterUserChatData(item, value)
  );



  const openFirstChat = async (byDefaultConversationId) => {
    try {
      if (byDefaultConversationId) {
        const messageData = await axios.get(
          `${REACT_APP_SERVICE_URL}/${apiRoutes.listMessage}/${byDefaultConversationId}/${userId}`
        );
        setUserMessage(messageData.data.data);

      }



    } catch (error) {
      console.error(error);
    }
  };

  // scroll message

  useEffect(() => {
    scrollToBottom();
  }, [userMessage]);

  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  };



  // user Message by Id
  const UserMessageById = async (data) => {
    setInputMessage("")
    setIs_group(data.is_group)
    setCollapsed(true)
    setNamePic({
      userName: data.user_name,
      profilePicPath: data.profile_pic_path,
    });
    setActiveConversationId(true)
    try {
      const conversationId = data.conversation_id;
      setActiveUserId(conversationId);
      const messageData = await axios.get(
        `${REACT_APP_SERVICE_URL}/${apiRoutes.listMessage}/${conversationId}/${userId}`
      );
      setUserMessage(messageData.data.data);


    } catch (error) {
      console.error(error);
    }

  };

  const autoUpdateChat = async (activeUserId) => {


    try {
      const messageData = await axios.get(
        `${REACT_APP_SERVICE_URL}/${apiRoutes.listMessage}/${activeUserId}/${userId}`
      );
      setUserMessage(messageData.data.data);

    } catch (error) {
      console.log(error)

    }
  }

  function adjustTextareaHeight(e) {
    const textarea = e.target;
    textarea.style.height = 'auto'; // Reset height to recalculate
    textarea.style.height = `${textarea.scrollHeight}px`; // Set new height
  }

  // Press Enter key send Message 

  useEffect(() => {
    if (isEnterPressed && inputMessage.trim() !== "") {
      HandelSendMessage();
    }
    setIsEnterPressed(false);
  }, [isEnterPressed, inputMessage]);

  const pressEnterKey = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      setIsEnterPressed(true);
    }

  }

  // have message of user
  const HandelSendMessage = async () => {
    try {

      // fetchData();
      // const conversationId = userMessage[0].conversation_id;
      const conversationId = activeUserId;
      const userData = {
        conversation_id: conversationId,
        sender_id: userId,
        message_text: inputMessage,
      };

      await axios.post(
        `${REACT_APP_SERVICE_URL}/${apiRoutes.sendMessage}`,
        userData
      );
      await setInputMessage("");
      await UserMessageById({
        user_id: activeUserId,
        user_name: namepic.userName,
        profile_pic_path: namepic.profilePicPath,
        conversation_id: conversationId,
        is_group: is_group,
      });
      await fetchData();
      localStorage.removeItem("ModalData")
      scrollToBottom();
    } catch (error) {
      console.log(error);
    }

  };

  {/* model for createConversation */ }
  const handleCancel = () => {
    setVisible(false);
    window.location.href = "/chat";
    localStorage.removeItem("ModalData")
  };

  const handelCreateNewuser = () => {
    handleCreateConversation()
    setVisible(false);
  };
  {/* model for createConversation */ }

  return (
    <Layout className="chat-container">
      {/* model for createConversation */}

      {visible && <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        centered
        closable={false}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="start-new-chat">
            <div>Start a New Chat</div>
            <Button type="text" onClick={handleCancel} icon={<CloseOutlined />} />
          </div>
          <Space style={{ marginTop: '20px', marginBottom: '20px' }}>
            {ConvertModalValue ? (
              <>
                <img className="model-img" src={ConvertModalValue.profile_pic_path === null ? dummyUser : ConvertModalValue.profile_pic_path} alt="user avatar" />
                <p>{ConvertModalValue.name}</p>
              </>
            ) : (<>
              <img className="model-img" src={namepic.profilePicPath === null ? dummyUser : namepic.profilePicPath} alt="user avatar" />
              <p>{namepic.userName}</p>
            </>)}
          </Space>
          <div className="div-for-input">
            {/* <Input
              className="input-of-modal"
              value={modalInput}
              onChange={(e) => setModalInput(e.target.value)}
              placeholder="Type your message here..."
            /> */}
            <textarea
              className="input-of-modal"
              value={modalInput}
              onChange={(e) => {
                setModalInput(e.target.value);
                adjustTextareaHeight(e);

              }}
              placeholder="Send message..."
              onKeyDown={pressEnterKey}
              rows={1}
              style={{ resize: 'none', overflowY: 'auto', maxHeight: '250px', width: '100%' }} // This prevents manual resizing
            />

          </div>
          <Space style={{ marginTop: '20px', justifyContent: 'flex-end' }}>
            <Button className="cancel-button-modal" type="text" onClick={handleCancel}>Cancel</Button>
            <button className="mode-send-button" disabled={!modalInput.trim()} onClick={handelCreateNewuser}>
              <SendOutlined />
            </button>
          </Space>
        </div>
      </Modal>}
      {/* model for createConversation */}
      <Sider
        // className={` ${collapsed ? "false-all-caht":"chat-aside" }`}
        className={`chat-aside  ${collapsed ? "false-all-caht" : ""}`}
        trigger={null}
        collapsible
        // collapsed={collapsed}
        style={{
          background: "rgb(243 243 243)",
          transition: "width 0.5s ease-in-out",
        }}
        width={350}>
        <div className="chat-search text-center">
          <Input
            size="large"
            placeholder="Search user or start a chat..."
            prefix={<MdOutlineSearch />}
            style={{ padding: "10px" }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className="all-chat">
          {searchData.length > 0 ? (

            searchData.map((data, index) => (
              <div
                className={`user ${activeUserId === data.conversation_id && "active"
                  }`}


                key={index}
                onClick={() => UserMessageById(data)}>
                <div className="dummyUser">
                  <img
                    // className="dummyUser"
                    src={
                      data.profile_pic_path ? data.profile_pic_path : dummyUser
                    }
                    alt="img"
                  />
                </div>
                <div className="Namemessage">
                  {data.is_group === true ?
                    <p className="chat-username"><span style={{ marginRight: '4px', fontSize: '20px' }}><MdGroups /></span>{data.user_name}</p>
                    : <p className="chat-username">{data.user_name}</p>}
                  <p style={{ margin: '0', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.last_message}</p>
                </div>
                <div className="para">
                  <p className="date">{dateDisplay(data.last_message_time, true)}</p>

                  {data.unread_count > 0 && <Badge count={data.unread_count} style={{ background: "green" }} />}

                </div>
              </div>
            ))
          ) : (
            <span className="not-found ">No Chat !!</span>
          )}
        </div>
      </Sider>

      <Layout className={`site-layout ${collapsed ? 'true-collapesd' : 'false-collapesd'} `}>
        <Header
          className="site-layout-background"
          style={{
            padding: 5,
            background: "white",
            borderBottom: "1px solid lightgray",
          }}>
          {window.innerWidth <= 768 && (
            <div className={"trigger"} onClick={() => setCollapsed(!collapsed)}>
              {" "}
              {/* <ToggleButton /> */}
              {collapsed && <MdArrowBack />}
            </div>
          )}
          <div className="chat-user-detail">
            <img
              className="single-chat-user"
              src={namepic.profilePicPath ? namepic.profilePicPath : dummyUser}
              alt="img"
            />
            <p className="single-chat-phone">{is_group && < MdGroups style={{ fontSize: '29px', marginRight: '5px', marginBottom: '7px' }} />}{namepic.userName}</p>
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            // padding: 20,
            minHeight: 280,
            backgroundColor: "white",
            borderRadius: "5px",
          }}>
          <div ref={messagesRef} className="chat-data-here all-chat">
            {userMessage.length == 0 ? (
              <div className="empty-chat">
                {/* welcome to chat */}
              </div>
            ) : userMessage.map((data, index) => (
              <div
                key={index}
                className={`message ${data.sender_id === userId ? "sent" : "received"
                  }`}>
                {data.sender_id !== userId && is_group && <p style={{ position: 'absolute', top: '-5px' }}>{data.user_name}</p>}
                {data.sender_id !== userId && is_group && <img className="group-use-photo" src={data.profile_pic_path ? data.profile_pic_path : dummyUser} alt="User Avatar" />}
                <div className={`message-content ${data.sender_id !== userId && is_group && `group-caht-img-name`}`}>
                  {data.message_text}
                  <div className="TimeandCheck">

                    <span className="timestamp">
                      {dateDisplay(data.timestamp, false)}
                    </span>
                    <span className={`timestamp ${data.is_read && "message-read"}`}>{data.sender_id === userId && <RiCheckDoubleFill />}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {
            // <div className="chat-input-send">
            // {/* {!userMessage.length == 0 && <div className="chat-input-send"> */}
            // <input
            //   type="text"
            //   value={inputMessage}
            //   onChange={(e) => setInputMessage(e.target.value)}
            //   placeholder="Send message..."
            //   onKeyDown={pressEnterKey}
            // />
            // <button onClick={HandelSendMessage} disabled={!inputMessage.trim()}>
            //   <SendOutlined />
            // </button>
            // </div>
            <div className={`chat-input-send ${inputMessage === '' && 'inputmessageEmpty'}`} >
              <textarea
                value={inputMessage}
                onChange={(e) => {
                  setInputMessage(e.target.value);
                  adjustTextareaHeight(e);

                }}
                placeholder="Send message..."
                onKeyDown={pressEnterKey}
                rows={1}
                style={{ resize: 'none', overflowY: 'auto', padding: '5px', borderRadius: '5px', maxHeight: '150px', border: 'none', width: '100%' }} // This prevents manual resizing
              />
              <button onClick={HandelSendMessage} disabled={!inputMessage.trim()}>
                <SendOutlined />
              </button>
            </div>

          }
        </Content>
      </Layout>
    </Layout>
  );
};
export default Chat;
