import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, Button, Spinner, Container } from 'reactstrap';
import { FaCheck, FaTimes, FaSuitcaseRolling, FaBriefcase } from 'react-icons/fa';
import { formatTimeToTimeZone } from '../utils/dateConverter';
import UpArrow, { Seats } from '../utils/icon';
import { IoAirplane } from "react-icons/io5";

function DuffelFlights({ flights }) {
    const [isDuffelLoaded, setIsDuffelLoaded] = useState(false);
    const [expandedFlight, setExpandedFlight] = useState(null);
    const [parsedFlights, setParsedFlights] = useState([]);


    useEffect(() => {
        const loadDuffelScript = () => {
            const script = document.createElement('script');
            script.src = 'https://assets.duffel.com/components/3.7.19/duffel-payments.js';
            script.async = true;
            script.onload = () => {
                setIsDuffelLoaded(true);
            };
            document.body.appendChild(script);
        };

        if (!window.Duffel) {
            loadDuffelScript();
        } else {
            setIsDuffelLoaded(true);
        }
        if (flights.length > 0) {
            const parsedData = flights.map(flight => ({
                ...flight,
                flight_details: JSON.parse(flight.flight_details)
            }));
            setParsedFlights(parsedData);
        }
    }, [flights]);






    const toggleExpand = (index) => {
        setExpandedFlight(expandedFlight === index ? null : index);
    };


    const calculateDuration = (departure, arrival) => {
        if (!departure || !arrival) return 'N/A';

        const depTime = new Date(departure);
        const arrTime = new Date(arrival);

        const timeDiff = arrTime - depTime; // Difference in milliseconds

        if (timeDiff <= 0) return 'N/A'; // Handle invalid dates

        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

        return `${hours} h ${minutes} m`;
    };

    const handleCardClick = (flightId) => {
        window.location.href = `/myBooking/${flightId}`;

    };




    return (
        <>


            {
                isDuffelLoaded ? (<>

                    {flights.map((selectedFlight, index) => (
                        <Card className="mt-3 shadow-lg p-2 round" key={index}   >
                            <CardBody className="py-0 ">
                                <Row  >
                                    <Col lg="12" md="12" sm="12" xs="12" >


                                        <Row className="my-2 ">




                                            <Col lg="8" md="8" sm="8" xs="8" className=" text-left">
                                                {selectedFlight.flight_logo_url ? (
                                                    <img
                                                        style={{ width: "50px", height: "50px", borderRadius: "50%", border: "2px solid black" }}
                                                        src={selectedFlight.flight_logo_url}
                                                        alt="Profile"
                                                    />
                                                ) : (
                                                    <div
                                                        className="bg-white pr-0 text-center"
                                                        style={{ height: "50px", width: "50px", border: "2px solid black", borderRadius: "50%" }}
                                                    >
                                                        <h2 className="textInitial">
                                                            {selectedFlight.aircraft_name?.split(" ").map(n => n[0]).join("")}
                                                        </h2>
                                                    </div>
                                                )}
                                            </Col>

                                            <Col lg="4" md="4" sm="4" xs="4" className=" text-right">



                                                <Button
                                                    color="link"
                                                    onClick={() => handleCardClick(selectedFlight.BookingId)}
                                                    type='primary'
                                                    style={{
                                                        color: "#FFE000",
                                                        fontSize: "14px",
                                                        backgroundColor: "#3B5E75",
                                                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5) ! important",
                                                        transition: "transform 0.3s ease-in-out"
                                                    }}
                                                    onMouseEnter={(e) => e.target.style.transform = "scale(1.05)"}
                                                    onMouseLeave={(e) => e.target.style.transform = "scale(1)"}
                                                >
                                                    View Details
                                                </Button>


                                            </Col>


                                        </Row>











                                        <Row className="mt-3 d-flex flex-row px-0 px-md-3  " style={{ gap: "1rem" }}>

                                            <div className='d-flex flex-col justify-content-between'>
                                                <Col lg="4" md="4" sm="4" xs="4" className="my-1 mt-2 p-0">
                                                    <h2 className="m-0" style={{ fontWeight: "900", color: "#333333", fontSize: "30px" }}>
                                                        {selectedFlight.dep_airport || 'N/A'}
                                                    </h2>
                                                    <h5 className="m-0" style={{ wordBreak: 'break-word', fontSize: "14px" }}>
                                                        {selectedFlight.dep_city || 'N/A'} <br />
                                                        <span style={{ color: "blue" }}>Terminal {selectedFlight.origin_terminal}</span>
                                                    </h5>
                                                </Col>

                                                <Col lg="4" md="4" sm="4" xs="4" className="mt-2 p-0 text-center d-flex flex-column justify-content-center align-items-center gap-1 position-relative dashed-line">
                                                    {/* Airplane Icon */}
                                                    <IoAirplane style={{ color: "#FFE600", fontSize: "20px" }} />

                                                    {/* Journey Hours */}
                                                    <span className="d-inline-block" style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                                                        {calculateDuration(selectedFlight.dep_date, selectedFlight.arr_date)}
                                                    </span>
                                                </Col>

                                                <Col lg="4" md="4" sm="4" xs="4" className="my-1 p-0 text-right">
                                                    <h2 className="m-0" style={{ fontWeight: "900", color: "#333333", fontSize: "30px" }}>
                                                        {selectedFlight.arr_airport || 'N/A'}
                                                    </h2>
                                                    <h5 className="m-0" style={{ wordBreak: 'break-word', fontSize: "14px" }}>
                                                        {selectedFlight.arr_city || 'N/A'} <br />
                                                        <span style={{ color: "blue" }}>Terminal {selectedFlight.destination_terminal}</span>
                                                    </h5>
                                                </Col>
                                            </div>












                                            <div className='d-flex flex-col justify-content-between ' >
                                                <Col lg="12" md="6" sm="12" xs="7">
                                                    <h4 className="mb-1 font-weight-normal ">Changes</h4>
                                                    <p className="m-0" style={{ color: selectedFlight.is_modifiable ? 'green' : 'red' }}>
                                                        {selectedFlight.is_modifiable ? (
                                                            <><FaCheck color="green" /> Allowed</>
                                                        ) : (
                                                            <><FaTimes color="red" /> Not Allowed</>
                                                        )}
                                                    </p>
                                                    {selectedFlight.modification_penalty > 0 && (
                                                        <p className="m-0">Penalty: {selectedFlight.modification_currency} {selectedFlight.modification_penalty}</p>
                                                    )}
                                                </Col>


                                                <Col lg="12" md="6" sm="12" xs="5" className="my-1 pr-0">
                                                    <h4 className="mb-1 font-weight-normal ">Price</h4>
                                                    <h4 className="m-0 font-weight-bold" style={{ color: "#2F78FF" }}>
                                                        {selectedFlight.total_amount ? "$" + selectedFlight.total_amount : "Upon Request"}
                                                    </h4>
                                                    <h5 className="m-0">{selectedFlight.is_charter ? "Total" : "Total"}</h5>
                                                    {/* <h5 className="m-0">{selectedFlight.is_charter ? "Charter" : "Per seat"}</h5> */}
                                                </Col>
                                            </div>






                                        </Row>

                                        <Row className="mt-3 d-flex flex-column flex-md-row px-0 px-md-3  " >

                                            <div className='d-flex justify-content-between  w-50 '  >

                                                <Col lg="6" md="6" sm="12" xs="12" className="my-1 mt-2 p-0">
                                                    <h4 className="mb-1 font-weight-normal ">Departs</h4>


                                                    {parsedFlights.length > 0 && (
                                                        <h5 className="m-0  font-weight-bold">
                                                            {formatTimeToTimeZone(
                                                                parsedFlights[index]?.flight_details[0]?.segments[0]?.departing_at,
                                                                parsedFlights[index]?.flight_details[0]?.segments[0]?.destination?.time_zone
                                                            ) || 'N/A'}
                                                        </h5>
                                                    )}
                                                </Col>






                                                <Col lg="6" md="6" sm="12" xs="12" className="my-1 p-0 text-right">
                                                    <h4 className="mb-1 font-weight-normal ">Arrives</h4>



                                                    {parsedFlights.length > 0 && (
                                                        <h5 className="m-0 font-weight-bold ">
                                                            {formatTimeToTimeZone(
                                                                parsedFlights[index]?.flight_details[0]?.segments[0]
                                                                    ?.arriving_at,
                                                                parsedFlights[index]?.flight_details[0]?.segments[0]?.origin?.time_zone
                                                            ) || 'N/A'}
                                                        </h5>
                                                    )}





                                                </Col>

                                            </div>


                                            <div className='d-flex  justify-content-between   w-50 '  >


                                                <Col lg="6" md="6" sm="12" xs="12" className='' >
                                                    <h4 className="mb-1 font-weight-normal ml-0 ml-md-4  ">Cancellation</h4>
                                                    <p className="m-0 ml-0 ml-md-4 " style={{ color: selectedFlight.is_cancellable ? 'green' : 'red' }}>
                                                        {selectedFlight.is_cancellable ? (
                                                            <>
                                                                <FaCheck color="green" /> <span className='font-weight-normal'>Allowed</span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <FaTimes color="red" /> <span className='font-weight-normal'>Not Allowed</span>
                                                            </>
                                                        )}
                                                    </p>





                                                    {selectedFlight.cancellation_penalty > 0 && (
                                                        <p className="m-0">Penalty: {selectedFlight.cancellation_currency} {selectedFlight.cancellation_penalty}</p>
                                                    )}
                                                </Col>

                                                <Col lg="6" md="6" sm="12" xs="12" className="my-1 pr-0 ">
                                                    <h4 className="mb-1 font-weight-normal">Seats</h4>
                                                    <h4 className="m-0 font-weight-bold "> <Seats /> {selectedFlight && selectedFlight?.passengers && selectedFlight?.passengers.length > 0 ? selectedFlight.passengers.length : 'N/A'}</h4>
                                                </Col>
                                            </div>




                                        </Row>
                                        {/* <Row className="mt-2">
                                    <Col lg="12" md="12" sm="12" xs="12">
                                        <h6 className="m-0">Amenities</h6>
                                        <p className="m-0">
                                            {selectedFlight.wifi && <><FaWifi /> Wi-Fi</>}
                                            {selectedFlight.power && <><FaPlug className="ml-2" /> Power</>}
                                        </p>
                                    </Col>
                                           </Row> */}


                                        {/* Second Row is started =========> */}


                                        {
                                            selectedFlight && selectedFlight?.passengers && selectedFlight?.passengers.length > 0 && (<>






                                                <Row className="mt-0">
                                                    <Col lg="12" md="12" sm="12" xs="12" >
                                                        <Button color="link" onClick={() => toggleExpand(index)} type='primary' style={{ color: "#139ED8", fontSize: "15px" }}>
                                                            {expandedFlight === index ? 'Hide Passengers ' : 'Show Passengers'}

                                                            <UpArrow style={{
                                                                transition: 'transform 0.3s ease',
                                                                transform: expandedFlight === index ? 'rotate(0deg)' : 'rotate(180deg)'
                                                            }} />

                                                        </Button>

                                                        {expandedFlight === index && (
                                                            <div style={{ width: "100%", color: "#FFE000", backgroundColor: "#3b5e75d1" }}>
                                                                {selectedFlight.passengers?.map((passenger, pIndex) => (
                                                                    <Card className=" shadow   mt-1  px-3 px-md-5 py-3 d-flex flex-column flex-md-row  justify-content-between" style={{ backgroundColor: "transparent", border: "none" }}  >
                                                                        <div key={pIndex}>
                                                                            <p className='font-weight-bolder'>Passenger Details</p>
                                                                            <p className='mb-2'><span className='font-weight-normal'>Name</span> : <span className='font-weight-bold'> {passenger.title} {passenger.first_name} {passenger.last_name} </span>   </p>
                                                                            <p className='mb-2'><span className='font-weight-normal'>Email</span> :<span className='font-weight-bold'> {passenger.email}                                              </span>  </p>
                                                                            <p className='mb-2'><span className='font-weight-normal'>DOB</span> :  <span className='font-weight-bold'> {passenger.date_of_birth}                                      </span>  </p>
                                                                        </div>

                                                                        <div className='mt-3 mt-md-0'>
                                                                            <p className='font-weight-bolder'>Baggage</p>
                                                                            <p className="mb-2">
                                                                                <FaSuitcaseRolling /> Checked: {passenger.checked_baggage || 'N/A'}
                                                                            </p>
                                                                            <p className="m-0">
                                                                                <FaBriefcase /> <span className='font-weight-normal'> Carry-on </span>:  <span className='font-weight-bold'>{passenger.carryon_baggage || 'N/A'}</span>
                                                                            </p>
                                                                        </div>

                                                                    </Card>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>

                                            </>)
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    ))}

                </>) : (<> <Container className="text-center mt-5">
                    <Spinner color="primary" />
                </Container></>)
            }

        </>
    );
}

export default DuffelFlights;