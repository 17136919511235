import React, { useEffect, useState } from "react";
import { Container, Row, Card, Alert, CardBody, CardHeader } from "reactstrap";
import GroupList from "../components/MyGroups/GroupList";
import AddGroupData from "../components/MyGroups/AddGroup";
import EditGroupData from "../components/MyGroups/EditGroup";
import { useLocalStorage } from "../utils/useLocalStorage";
import { useGroupsData } from '../data/GroupsData'
import GroupProfilePic from "../components/MyGroups/GroupProfilePic";
import MyGroupDetails from "../components/MyGroups/MyGroupDetails";
import MembersList from "../components/GroupDetails/MembersList";
import AddMember from "../components/GroupDetails/AddMember";
import { useMediaQuery } from "@material-ui/core";

function MyGroups() {
  const [user, SetUser] = useLocalStorage("auth", {});
  const { MyGroups, DeleteGroupApi, JoinedGroup, GroupMembers, RemoveGroupMember } =
    useGroupsData();
  const [isGroupAdded, SetGroupAdded] = useState(false);
  const [isGroupDeleted, SetGroupDeleted] = useState(false);
  const [isGroupUpdated, SetGroupUpdated] = useState(false);
  const [isUpdate, SetIsUpdate] = useState(false);
  const [groupData, setGroupData] = useState(undefined);
  const [joinedGroupData, SetJoinedGroupData] = useState(undefined);
  const [editedGroupData, setEditedGroupData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [membersData, SetMembersData] = useState(undefined);
  const [isGroupMemberRemoved, setGroupMemberRemoved] = useState(false);
  const [removeMemberData, SetRemoveMemberData] = useState(null);
  const [deleteGroupData, SetDeleteGroupData] = useState(null);
  const isDesktop = useMediaQuery('(min-width:768px)');

  useEffect(() => {
    GetMyGroups();
    GetJoinedGroups();
  }, []);

  useEffect(() => {
    if (isGroupAdded) {
      setTimeout(function () {
        SetGroupAdded(false);
      }, 3000);
    }
    if (isGroupDeleted) {
      setTimeout(function () {
        SetGroupDeleted(false);
      }, 3000);
    }
    if (isGroupUpdated) {
      setTimeout(function () {
        SetGroupUpdated(false);
      }, 3000);
    }
    if (isGroupMemberRemoved) {
      setTimeout(function () {
        setGroupMemberRemoved(false);
      }, 3000);
    }
    GetMyGroups();
  }, [
    isGroupAdded,
    isGroupDeleted,
    isGroupUpdated,
    isUpdate,
    isGroupMemberRemoved,
  ]);

  async function GetGroupMembers(groupid) {
    const response = await GroupMembers(groupid, user);
    SetMembersData(response);
  }

  async function DeleteGroup() {
    const DeleteGroupData = {
      userid: user.userid,
      group_id: deleteGroupData.group_id,
    };
    const response = await DeleteGroupApi(DeleteGroupData, user);
    SetGroupDeleted(true);
  }

  async function EditGroup(event, data) {
    setEditedGroupData(data);
    SetIsUpdate(true);
  }

  async function GetMyGroups() {
    const response = await MyGroups(user.userid, user);
    setGroupData(response);
    if (response.length > 0) GetGroupMembers(response[0].group_id);
  }

  async function GetJoinedGroups() {
    const response = await JoinedGroup(user.userid, user);
    console.log("Joined", response)
    SetJoinedGroupData(response);
  }

  async function RemoveMember() {
    const response = await RemoveGroupMember(user.userid, removeMemberData.member_id, user);
    if (response) {
      setGroupMemberRemoved(true);
    }
    SetRemoveMemberData(null);
  }



  return (
    <div>
      {/* {isError && <Alert color="danger">{errorMessage}</Alert>} */}

      <Container fluid style={{ paddingLeft: isDesktop ? "6rem" : "", paddingRight: isDesktop ? "6rem" : "" }}>
        {isGroupAdded && (
          <Alert className="alertBlue text-white">Group Added Successfully.</Alert>
        )}

        {isGroupMemberRemoved && (
          <Alert className="alertBlue text-white">
            Group member removed from group Successfully.
          </Alert>
        )}

        {isGroupDeleted && (
          <Alert className="alertBlue text-white">Group Deleted Successfully.</Alert>
        )}
        {isGroupUpdated && (
          <Alert className="alertBlue text-white">Group Updated Successfully.</Alert>
        )}
        <Row>
          <div className="col p-0">
            <Card className="shadow" style={{ backgroundColor: "#fff" }}>
              <Card>
                {groupData && groupData.length > 0 && (
                  <GroupProfilePic
                    groupData={groupData}
                    setIsError={setIsError}
                    setErrorMessage={setErrorMessage}
                  />
                )}
                {isUpdate && (
                  <EditGroupData
                    user={user}
                    SetGroupUpdated={SetGroupUpdated}
                    SetIsUpdate={SetIsUpdate}
                    editedGroupData={editedGroupData}
                  />
                )}
                {groupData && groupData.length < 1 ? (
                  <AddGroupData
                    user={user}
                    SetGroupAdded={SetGroupAdded}
                    SetUser={SetUser}
                  />
                ) : (
                  <>
                    <MyGroupDetails
                      groupData={groupData}
                      isallowEditAndDelete={true}
                      DeleteGroup={DeleteGroup}
                      EditGroup={EditGroup}
                      SetDeleteGroupData={SetDeleteGroupData}
                    />
                    <AddMember
                      membersData={membersData}
                      user={user}
                      groupDetails={groupData}
                    />
                    {/* <GroupList
                      groupData={groupData}
                      isallowEditAndDelete={true}
                      DeleteGroup={DeleteGroup}
                      EditGroup={EditGroup}
                    /> */}
                  </>
                )}
              </Card>
              <Card>
                <CardBody className="p-0">
                  {membersData && membersData.length > 0 ? (
                    <MembersList
                      membersData={membersData}
                      RemoveMember={RemoveMember}
                      SetRemoveMemberData={SetRemoveMemberData}
                    />
                  ) : (
                    <Alert className="alertBlue text-white">
                      No Group Members Added.
                    </Alert>
                  )}
                </CardBody>
              </Card>
              <hr className="m-3" />
              <Card>
                <CardHeader className="text-left p-0">
                  <h2>&nbsp;Joined Groups</h2>
                </CardHeader>
                {joinedGroupData && joinedGroupData.length < 1 && (
                  <Alert className="m-2 alertBlue text-white">
                    You haven't joined any group.
                  </Alert>
                )}
                {joinedGroupData && joinedGroupData.length > 0 && (
                  <GroupList
                    groupData={joinedGroupData}
                    isallowEditAndDelete={false}
                    DeleteGroup={DeleteGroup}
                    EditGroup={EditGroup}
                    GetJoinedGroups={GetJoinedGroups}
                  />
                )}
              </Card>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default MyGroups;
