import React from 'react'
import { HiDownload } from "react-icons/hi";
import { IoAirplane } from 'react-icons/io5'
import { MdArrowBack } from 'react-icons/md'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import { ConfirmIcon, Seats } from '../../utils/icon'
import { formatSimpleDate, formatTimeToTimeZone } from '../../utils/dateConverter';
import { FaBriefcase, FaCheck, FaSuitcaseRolling, FaTimes } from 'react-icons/fa';

const BookingDetails = ({ detail }) => {



    // console.log(detail, "detail check")

    const backToBookings = () => {
        window.location.href = '/myBookings'
    }

    const calculateDuration = (departure, arrival) => {
        if (!departure || !arrival) return 'N/A';

        const depTime = new Date(departure);
        const arrTime = new Date(arrival);

        const timeDiff = arrTime - depTime; // Difference in milliseconds

        if (timeDiff <= 0) return 'N/A'; // Handle invalid dates

        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

        return `${hours} h ${minutes} m`;
    };
    return (
        <div className='d-flex flex-column gap-4  ' >
            <div className='d-flex flex-row justify-content-between'>
                <Button color="link" onClick={() => backToBookings()} type='primary' style={{ color: "#FFE000", fontSize: "14px", backgroundColor: "#3B5E75" }}>
                    <MdArrowBack />

                </Button>

                <Button color="link" type='primary' style={{ color: "#FFE000", fontSize: "14px", backgroundColor: "#3B5E75" }}>
                    <HiDownload size={18} /> PDF



                </Button>

            </div>



            {/* booking confirm started  */}
            <div >
                <Card className="mt-3 shadow-lg p-0  round"    >
                    <CardBody className="py-0 px-0 ">
                        <Row  >
                            <Col lg="12" md="12" sm="12" xs="12" >


                                <Row className=" ">
                                    <Col lg="12" md="12" sm="12" xs="12" className=" text-start ">
                                        <div
                                            className="d-flex py-4 flex-column  text-left pl-4 "
                                            style={{ backgroundColor: "#3B5E75" }}
                                        >
                                            <div className="mb-0 d-flex align-items-center font-weight-bold text-success">
                                                <ConfirmIcon className="mr-2" />
                                                <span className="display-4 mb-0 d-none d-md-block" style={{ color: "#FFE000", fontWeight: "900" }}>BOOKING CONFIRMED</span>
                                                <span className="h2 mb-0 d-block d-md-none" style={{ color: "#FFE000", fontWeight: "900" }}>BOOKING CONFIRMED</span>
                                            </div>


                                        </div>

                                    </Col>
                                </Row>

                                <Row className="  ">
                                    <Col lg="12" md="12" sm="12" xs="12" className=" text-start ">
                                        <div
                                            className="  text-left pl-4 mt-5 mb-2 "

                                        >
                                            <div className="  d-flex align-items-center   font-weight-normal" style={{ color: "#016A38", fontWeight: "600" }}>

                                                {detail.flight_logo_url ? (
                                                    <img
                                                        style={{ width: "50px", height: "50px", borderRadius: "50%", border: "2px solid gray" }}
                                                        src={detail.flight_logo_url}
                                                        alt="Profile"
                                                    />
                                                ) : (
                                                    <div
                                                        className="bg-white pr-0 text-center"
                                                        style={{ height: "50px", width: "50px", border: "2px solid gray", borderRadius: "50%" }}
                                                    >
                                                        <h2 className="textInitial">
                                                            {detail.aircraft_name?.split(" ").map(n => n[0]).join("")}
                                                        </h2>
                                                    </div>
                                                )}  <span className='ml-2 mb-0 font-weight-bold h3'>{detail.airlines_name
                                                }</span>


                                            </div>


                                        </div>

                                    </Col>
                                </Row>



                                <Row className="mt-3 d-flex flex-row px-0 px-md-3  " style={{ gap: "1rem" }}>

                                    <div className='d-flex w-100 flex-col justify-content-between px-4 '  >

                                        <Col lg="4" md="4" sm="4" xs="4" className="my-1 mt-2 p-0">
                                            <h2 className="m-0" style={{ fontWeight: "900", color: "#333333", fontSize: "40px" }}>{detail?.dep_airport || 'N/A'}</h2>
                                            <h3 className="m-0" style={{ wordBreak: 'break-word', fontSize: "18px" }} >{detail.dep_city || 'N/A'} <br /> <strong> Terminal {detail.origin_terminal}</strong></h3>
                                        </Col>

                                        <Col lg="4" md="4" sm="4" xs="4" className="mt-2 p-0 text-center d-flex flex-column align-items-center gap-1">
                                            {/* Airplane Icon */}
                                            <IoAirplane style={{ color: "#FFE600", width: "35px", height: "35px" }} />

                                            {/* Journey Hours */}
                                            <span className="d-inline-block" style={{ textAlign: 'center', whiteSpace: 'nowrap', fontSize: "16px " }}>
                                                {calculateDuration(detail.dep_date, detail.arr_date)}
                                            </span>
                                        </Col>


                                        <Col lg="4" md="4" sm="4" xs="4" className="my-1 p-0 text-right">
                                            <h2 className="m-0" style={{ fontWeight: "900", color: "#333333", fontSize: "40px" }}>{detail.arr_airport || 'N/A'}</h2>
                                            <h5 className="m-0  " style={{ wordBreak: 'break-word', fontSize: "18px" }}>{detail.arr_city || 'N/A'} <br /> <strong> Terminal {detail.destination_terminal}</strong></h5>
                                        </Col>


                                    </div>

                                </Row>

                                <Row className=" mb-3 d-flex flex-column flex-md-row px-0 px-md-3  " >

                                    <div className='d-flex  justify-content-between  w-100 px-4 '  >

                                        <Col lg="6" md="6" sm="6" xs="6" className="my-1 mt-2 p-0">
                                            <h3 className="mb-1 font-weight-bold ">Departs</h3>


                                            {detail && (
                                                <h5 className="m-0  font-weight-bold">
                                                    {formatTimeToTimeZone(
                                                        detail.flight_details[0]?.segments[0]?.departing_at,
                                                        detail.flight_details[0]?.segments[0]?.destination?.time_zone
                                                    ) || 'N/A'}
                                                </h5>
                                            )}
                                        </Col>






                                        <Col lg="6" md="6" sm="6" xs="6" className="my-1 p-0 text-right">
                                            <h3 className="mb-1 font-weight-bold ">Arrives</h3>



                                            {detail && (
                                                <h5 className="m-0 font-weight-bold ">
                                                    {formatTimeToTimeZone(
                                                        detail.flight_details[0].segments[0]
                                                            .arriving_at,
                                                        detail?.flight_details[0].segments[0]?.origin?.time_zone
                                                    ) || 'N/A'}
                                                </h5>
                                            )}





                                        </Col>

                                    </div>







                                </Row>


                                {/* Second Row is started =========> */}



                            </Col>
                        </Row>
                    </CardBody>
                </Card>

            </div>

            {/* booking confirm end  */}














            {/* booking info started  */}

            <div className='d-flex  flex-column flex-md-row  w-100'>
                {/* left  */}
                <div className=' w-100 w-md-50 mr-0 mr-md-3' >








                    <Card className="mt-3 shadow-lg p-0  round"    >
                        <CardBody className="py-0 px-0 ">
                            <Row  >
                                <Col lg="12" md="12" sm="12" xs="12" >


                                    <Row className=" ">
                                        <Col lg="12" md="12" sm="12" xs="12" className=" text-start ">
                                            <div
                                                className="d-flex py-2 flex-column  text-left pl-3 pl-md-4 "
                                                style={{ backgroundColor: "#3B5E75" }}
                                            >
                                                <h2 className=" mb-0 d-flex align-items-center  " style={{ color: "#FFE000", fontWeight: "600" }}>
                                                    Booking Information
                                                </h2>

                                            </div>

                                        </Col>
                                    </Row>

                                    <Row className="  ">
                                        <Col lg="12" md="12" sm="12" xs="12" className="text-start">
                                            <div className="d-flex flex-column w-100  text-left pl-3 pl-md-4 mt-3  mb-3" >

                                                <div className="mt-1 d-flex align-items-center">
                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Booking ID</p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2 font-weight-bold">:</span>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600", color: "#333333" }}>{detail.BookingId}</p>
                                                    </div>
                                                </div>

                                                <div className="mt-2 d-flex align-items-center">
                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Confirmation Number</p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">:</span>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>{detail.confirmation_number
                                                        }</p>
                                                    </div>
                                                </div>

                                                <div className="mt-2 d-flex align-items-center">
                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Booking Date</p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">:</span>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>{formatSimpleDate(detail
                                                            .booking_date)}</p>
                                                    </div>
                                                </div>

                                                <div className="mt-2 d-flex align-items-center">
                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Status</p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">:</span>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600", color: "green" }}>Success</p>
                                                    </div>
                                                </div>

                                                <div className="mt-2 d-flex align-items-center">
                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Is Cancellable</p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">:</span>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600", color: detail.is_cancellable ? "green" : "red" }}>
                                                            {detail.is_cancellable ? (
                                                                <>
                                                                    <FaCheck color="green" /> <span className='font-weight-normal'>Allowed</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <FaTimes color="red" /> <span className='font-weight-normal'>Not Allowed</span>
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="mt-2 d-flex align-items-center">
                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Is Modifiable</p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">:</span>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600", color: detail.is_modifiable ? "green" : "red" }}>
                                                            {detail.is_modifiable ? (
                                                                <>
                                                                    <FaCheck color="green" /> <span className='font-weight-normal'>Allowed</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <FaTimes color="red" /> <span className='font-weight-normal'>Not Allowed</span>
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>


                                                {detail.is_cancellable && (<>






                                                    <div className="mt-2 d-flex align-items-center">
                                                        <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Cancellation Penality</p>
                                                        <div className="d-flex align-items-center">
                                                            <span className="mr-2">:</span>
                                                            <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>{detail.cancellation_currency
                                                            }&nbsp;{detail.cancellation_penalty
                                                                }</p>
                                                        </div>
                                                    </div>

                                                </>)
                                                }

                                                {detail.is_modifiable && (<>



                                                    <div className="mt-2 d-flex align-items-center">
                                                        <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Modification Penalty</p>
                                                        <div className="d-flex align-items-center">
                                                            <span className="mr-2">:</span>
                                                            <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>{detail.modification_currency
                                                            }&nbsp;{detail.modification_penalty}</p>
                                                        </div>
                                                    </div>



                                                </>)}




                                            </div>
                                        </Col>

                                    </Row>









                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </div>

                {/* right  */}

                <div className='w-100 w-md-50' >








                    <Card className="mt-3 shadow-lg p-0  round"    >
                        <CardBody className="py-0 px-0 ">
                            <Row  >
                                <Col lg="12" md="12" sm="12" xs="12" >


                                    <Row className=" ">
                                        <Col lg="12" md="12" sm="12" xs="12" className=" text-start ">
                                            <div
                                                className="d-flex py-2 flex-column  text-left pl-3 pl-md-4 "
                                                style={{ backgroundColor: "#3B5E75" }}
                                            >
                                                <h2 className=" mb-0 d-flex align-items-center  " style={{ color: "#FFE000", fontWeight: "600" }}>
                                                    Flight Information
                                                </h2>

                                            </div>

                                        </Col>
                                    </Row>

                                    <Row className="  ">
                                        <Col lg="12" md="12" sm="12" xs="12" className="text-start">
                                            <div className="d-flex flex-column w-100  text-left pl-3 pl-md-4 mt-3  mb-3" >

                                                <div className="mt-1 d-flex align-items-center">
                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Airline</p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2 font-weight-bold">:</span>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600", color: "#333333" }}>  <span className='font-weight-bold'>{detail.airlines_name
                                                        }</span></p>
                                                    </div>
                                                </div>

                                                <div className="mt-2 d-flex align-items-center">
                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Aircraft</p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">:</span>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>{detail.aircraft_name}</p>
                                                    </div>
                                                </div>

                                                <div className="mt-2 d-flex align-items-center">
                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Flight Number</p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">:</span>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>{detail.flight_number
                                                        }</p>
                                                    </div>
                                                </div>

                                                <div className="mt-2 d-flex align-items-center">
                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Flight Duration</p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">:</span>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}> {calculateDuration(detail.dep_date, detail.arr_date)}</p>
                                                    </div>
                                                </div>

                                                <div className="mt-2 d-flex align-items-center">
                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Seats</p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">:</span>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}> <Seats />&nbsp;
                                                            {detail?.passengers?.length > 0 ? detail.passengers.length : "-"}
                                                        </p>

                                                    </div>
                                                </div>

                                                <div className="mt-2 d-flex align-items-center">
                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Seats No.</p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">:</span>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>{
                                                            detail.flight_details[0].segments[0].passengers[0].seat || "-"}</p>
                                                    </div>
                                                </div>

                                                <div className="mt-2 d-flex align-items-center">
                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Class</p>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">:</span>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>{
                                                            detail.flight_details[0].segments[0].passengers[0].cabin_class.toUpperCase() || "-"}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </Col>

                                    </Row>








                                    {/* Second Row is started =========> */}



                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </div>



            </div>

            {/* booking info end  */}

            {/* passendgers details started  */}

            <div>

                <div className='d-flex flex-row  w-100'>
                    {/* left  */}
                    <div className='mr-3 w-100' >








                        <Card className="mt-3 shadow-lg p-0  round"    >
                            <CardBody className="py-0 px-0 ">
                                <Row  >
                                    <Col lg="12" md="12" sm="12" xs="12" >


                                        <Row className=" ">
                                            <Col lg="12" md="12" sm="12" xs="12" className=" text-start ">
                                                <div
                                                    className="d-flex py-2 flex-column  text-left pl-3 pl-md-4 "
                                                    style={{ backgroundColor: "#3B5E75" }}
                                                >
                                                    <h2 className=" mb-0 d-flex align-items-center  " style={{ color: "#FFE000", fontWeight: "600" }}>
                                                        Passenger Details
                                                    </h2>

                                                </div>

                                            </Col>
                                        </Row>

                                        <Row className="  ">
                                            <Col lg="12" md="12" sm="12" xs="12" className="text-start">




                                                {/* for mobile  */}
                                                <Col lg="12" md="12" sm="12" xs="12" className="text-start">
                                                    <div className="d-flex d-md-none flex-column w-100 text-left pl-3 pl-md-4 mt-3 mb-3">
                                                        {detail.passengers?.map((passenger, pIndex) => (
                                                            <div key={pIndex} className="mt-2 d-flex flex-column border p-3">
                                                                <div className="d-flex align-items-center">
                                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Name</p>
                                                                    <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>
                                                                        {passenger.title} {passenger.first_name} {passenger.last_name}
                                                                    </p>
                                                                </div>

                                                                <div className="d-flex align-items-center mt-2">
                                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Seat No.</p>
                                                                    <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>-</p>
                                                                </div>

                                                                <div className="d-flex align-items-center mt-2">
                                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>DOB</p>
                                                                    <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>{passenger.date_of_birth}</p>
                                                                </div>

                                                                <div className="d-flex align-items-center mt-2">
                                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Email</p>
                                                                    <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>{passenger.email}</p>
                                                                </div>

                                                                <div className="d-flex align-items-center mt-2">
                                                                    <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Baggage</p>
                                                                    <div className="d-flex flex-row" style={{ fontSize: "18px", fontWeight: "500", color: "#000000" }}>
                                                                        <p className="mb-2 mr-2">
                                                                            <FaSuitcaseRolling style={{ color: "#000000" }} /> Checked: {passenger.checked_baggage}
                                                                        </p>
                                                                        <span>|</span>
                                                                        <p className="mb-2 ml-2">
                                                                            <FaBriefcase style={{ color: "#000000" }} /> <span className='font-weight-normal'>Carry-on</span>: <span className='font-weight-bold'>{passenger.carryon_baggage}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Col>

                                                {/* for mobile  */}



                                                <div className=" d-none d-md-block table-responsive">
                                                    <table class="table w-100 mt-0 mb-3">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ fontSize: "18px", fontWeight: "500" }}>Name</th>
                                                                <th className="text-center" style={{ fontSize: "18px", fontWeight: "500" }}>Seat No.</th>
                                                                <th className="text-center" style={{ fontSize: "18px", fontWeight: "500" }}>DOB</th>
                                                                <th className="text-center" style={{ fontSize: "18px", fontWeight: "500" }}>Email</th>
                                                                <th className="text-center" style={{ fontSize: "18px", fontWeight: "500" }}>Baggage</th>
                                                            </tr>
                                                        </thead>

                                                        {detail.passengers?.map((passenger, pIndex) => (
                                                            <tbody>
                                                                <tr key={pIndex}>
                                                                    <td class="" style={{ fontSize: "18px", fontWeight: "500" }}>


                                                                        {passenger.title} {passenger.first_name} {passenger.last_name}

                                                                    </td>





                                                                    <td className="" style={{ fontSize: "18px", fontWeight: "500" }}>-</td>
                                                                    <td className="" style={{ fontSize: "18px", fontWeight: "500" }}>{passenger.date_of_birth}</td>
                                                                    <td className="" style={{ fontSize: "18px", fontWeight: "500" }}>{
                                                                        passenger.email}</td>
                                                                    <td className=" d-flex flex-row " style={{ fontSize: "18px", fontWeight: "500", color: "#000000" }}><p className="mb-2  mr-2 ">
                                                                        <FaSuitcaseRolling style={{ color: "#000000" }} /> Checked: {passenger.checked_baggage}
                                                                    </p> | <p className="mb-2 ml-2"> <FaBriefcase style={{ color: "#000000" }} /> <span className='font-weight-normal'> Carry-on </span>:   <span className='font-weight-bold'>{passenger.carryon_baggage}</span></p></td>
                                                                </tr>


                                                            </tbody>

                                                        ))}
                                                    </table>

                                                </div>



                                            </Col>

                                        </Row>









                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </div>
                </div>

            </div>
            {/* passendgers details end */}

            {/* payment info started  */}

            <div>

                <div className='d-flex flex-row  w-100'>
                    {/* left  */}
                    <div className='mr-3 w-100' >








                        <Card className="mt-3 shadow-lg p-0  round"    >
                            <CardBody className="py-0 px-0 ">
                                <Row  >
                                    <Col lg="12" md="12" sm="12" xs="12" >


                                        <Row className=" ">
                                            <Col lg="12" md="12" sm="12" xs="12" className=" text-start ">
                                                <div
                                                    className="d-flex py-2 flex-column  text-left pl-3 pl-md-4 "
                                                    style={{ backgroundColor: "#3B5E75" }}
                                                >
                                                    <h2 className=" mb-0 d-flex align-items-center  " style={{ color: "#FFE000", fontWeight: "600" }}>
                                                        Payment Information
                                                    </h2>

                                                </div>

                                            </Col>
                                        </Row>

                                        <Row className="  ">

                                            {/* for mobile  */}

                                            <Col lg="12" md="12" sm="12" xs="12" className="text-start">
                                                <div className="d-flex d-md-none flex-column w-100 text-left pl-3 pl-md-4 mt-3 mb-3">


                                                    <div className="mt-2 d-flex align-items-center">
                                                        <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Currency</p>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>
                                                            {detail.PaymentCurrency || "-"}
                                                        </p>
                                                    </div>

                                                    <div className="mt-2 d-flex align-items-center">
                                                        <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Payment Method</p>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600" }}>Visa</p>
                                                    </div>

                                                    <div className="mt-2 d-flex align-items-center">
                                                        <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Payment Status</p>
                                                        <p className="mb-0" style={{ fontSize: "18px", fontWeight: "600", color: "green" }}>
                                                            {detail.PaymentStatus === "succeeded" ? "Completed" : "-"}
                                                        </p>
                                                    </div>

                                                    <div className="mt-2 d-flex align-items-center">
                                                        <p className="mb-0 mr-2" style={{ fontSize: "18px", fontWeight: "500" }}>Total Amount Paid</p>
                                                        <h4 className="mb-0 font-weight-bold" style={{ color: "#2F78FF" }}>
                                                            {detail.PaymentCurrency} {detail.PaymentAmount}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </Col>

                                            {/* for mobile  */}
                                            <Col lg="12" md="12" sm="12" xs="12" className="text-start">





                                                <div className=" d-none d-md-block table-responsive">
                                                    <table className="table  w-100 mt-0 mb-3">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center" style={{ fontSize: "18px", fontWeight: "500" }}>Currency</th>
                                                                <th className="text-center" style={{ fontSize: "18px", fontWeight: "500" }}>Payment Method</th>
                                                                <th className="text-center" style={{ fontSize: "18px", fontWeight: "500" }}>Payment Status</th>
                                                                <th className="text-center" style={{ fontSize: "18px", fontWeight: "500" }}>Total Amount Paid</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-center" style={{ fontSize: "18px", fontWeight: "600" }}>{detail.PaymentCurrency || "-"}</td>
                                                                <td className="text-center" style={{ fontSize: "18px", fontWeight: "500" }}>Visa</td>
                                                                <td className="text-center" style={{ fontSize: "18px", fontWeight: "500", color: "green" }}>
                                                                    {detail.PaymentStatus === "succeeded" ? "Completed" : "-"}
                                                                </td>
                                                                <td className="text-center" style={{ fontSize: "18px", fontWeight: "500" }}>
                                                                    <h4 className="m-0 font-weight-bold" style={{ color: "#2F78FF" }}>
                                                                        {detail.PaymentCurrency} {detail.PaymentAmount}
                                                                    </h4>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>


                                            </Col>

                                        </Row>









                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </div>
                </div>

            </div>
            {/* payment info end  */}

            {/* terms and condition started  */}

            <p className='mt-4 mb-0' style={{ color: "#2F78FF" }}>
                <span style={{ color: "red" }}>*</span> By booking this flight, you agree to our terms and conditions regarding cancellations, changes, and travel requirements.
            </p>

            {/* terms and condition end  */}
            {/* note  */}

            <p className='mt-2' style={{ color: "#2F78FF" }}>
                <span className="my-auto" style={{ color: "red" }}>*</span> <span className="" style={{ color: "#333333", fontWeight: "600" }}>IMPORTANT NOTE: </span>Please make sure to arrive at <span style={{ fontWeight: "600", color: "blue" }} >Terminal {detail.origin_terminal}</span> no later than 2 hours before the scheduled departure time.
            </p>
            {/* note  */}
        </div>
    )
}

export default BookingDetails
