import { useEffect, useState, useRef } from "react";
import { Grid, Card, Select, MenuItem, useMediaQuery } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import { useLocalStorage } from "../utils/useLocalStorage";
import PathArea from "../components/PathArea";
import Search from "../components/HomePage/Search";
import Book from "../components/HomePage/Book";
import FlightList from "../components/FlightList";
import "./HomePage.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import CardComponent from "../components/HomeCardsNew";
import BannerBottom from "../assets/images/backgroundPlane.png";
import { HiChevronDown } from 'react-icons/hi';
function BarkAir() {
    const [user] = useLocalStorage("auth", {});
    const [pathType, setPathType] = useState("search");
    const [unregistedFlightsData, setFlightData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [value, setValue] = useState(0);
    const [sidebarTop, setSidebarTop] = useState(undefined);
    const [searchClick, setSearchClick] = useState(true);
    var [headerHeight, setHeaderHeight] = useState(125);
    const scrollToRef = useRef(null);
    const cardRef = useRef(null);
    const isDesktop = useMediaQuery('(min-width:992px)'); // Bootstrap's lg breakpoint
    const isLgScreen = useMediaQuery('(min-width:768px)'); // Bootstrap's lg breakpoint
    const [filterBy, setFilterBy] = useState("Departure_Time");

    useEffect(() => {
        const card = cardRef.current;
        if (card && card.scrollHeight > card.clientHeight) {
            card.style.height = "auto";
        }
    }, []); // E

    // Use effect to check if isSearchClick is set to true
    useEffect(() => {
        if (searchClick && scrollToRef.current) {
            // Get the top position of the scrollToRef element
            const topPosition =
                scrollToRef.current.getBoundingClientRect().top + window.pageYOffset;
            // Adjust for the header height
            const adjustedPosition = topPosition - headerHeight;
            // Scroll to the adjusted position
            window.scrollTo({
                top: adjustedPosition,
                behavior: "smooth",
            });
        }
    }, [searchClick]);

    useEffect(() => {
        if (searchClick) {
            const chatEl = document.querySelector(".sticky-component");
            if (chatEl) {
                const rect = chatEl.getBoundingClientRect();
                setSidebarTop(rect.top + window.pageYOffset);
            }
        }
    }, [searchClick]);

    useEffect(() => {
        if (!sidebarTop) return;

        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, [sidebarTop]);

    const isSticky = (e) => {
        const chatEl = document.querySelector(".sticky-component");
        const scrollTop = window.scrollY;
        const header = document.getElementById("top-header");

        if (header) {
            setHeaderHeight(header.offsetHeight);
            headerHeight = header.offsetHeight;
        }

        if (chatEl) {
            if (scrollTop >= sidebarTop - headerHeight) {
                chatEl.style.position = 'sticky';
                chatEl.style.top = `${headerHeight}px`;
                chatEl.style.width = '100%';
                chatEl.style.zIndex = '100';
                chatEl.style.backgroundColor = 'white';
            } else {
                chatEl.style.position = 'static';
                chatEl.style.top = 'auto';
            }
        }
    };

    useEffect(() => {
        const body = document.getElementsByTagName("body")[0];
        const h1 = document.createElement("h1");
        h1.innerText = "Book Shared Private Flights";
        h1.style.display = "none";
        body.insertBefore(h1, body.firstChild);
    }, []);

    useEffect(() => {
        setFilteredData(unregistedFlightsData);
        handleChange(null, 0);
        //setValue(1);
        //setFilteredData(unregistedFlightsData.filter((x) => x.is_posted == true && x.is_charter == false))
    }, [unregistedFlightsData]);

    const handleChange = (event, newValue) => {
        //setFilteredData(unregistedFlightsData);
        setValue(newValue);
        // //console.log("Flight list: "+unregistedFlightsData);
        // if (newValue == 0) {
        //   setFilteredData(unregistedFlightsData);
        // }
        // if (newValue == 1) {
        //   setFilteredData(
        //     unregistedFlightsData.filter(
        //       (x) => x.is_posted == true && x.is_charter == false
        //     )
        //   );
        // }
        // if (newValue == 2) {
        //   setFilteredData(
        //     unregistedFlightsData.filter((x) => x.is_charter == true)
        //   );
        // }
        // if (newValue == 3) {
        //   setFilteredData(
        //     unregistedFlightsData.filter((x) => x.is_posted == false)
        //   );
        // }

        let newFilteredData;
        switch (newValue) {
            case 0:
                newFilteredData = unregistedFlightsData;
                break;
            case 1:
                newFilteredData = unregistedFlightsData;
                break;
            case 2:
                newFilteredData = unregistedFlightsData.filter(
                    (x) => x.is_charter === true
                );
                break;
            case 3:
                newFilteredData = unregistedFlightsData.filter(
                    (x) => x.is_posted === false
                );
                break;
            default:
                newFilteredData = unregistedFlightsData;
        }

        setFilteredData(newFilteredData);
        setValue(newValue);
    };

    useEffect(() => {
        // console.log(unregistedFlightsData)
    }, [unregistedFlightsData])

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterBy(value);

        // Separate flights into valid priced and invalid priced arrays
        const validPricedFlights = filteredData.filter(
            (flight) => flight.total_price !== 0 && flight.total_price !== null
        );
        const invalidPricedFlights = filteredData.filter(
            (flight) => flight.total_price === 0 || flight.total_price === null
        );

        // Sort valid priced flights based on the selected criteria
        validPricedFlights.sort((a, b) => {
            switch (value) {
                case "Departure_Time":
                    const dateA = new Date(`${a.dep_date} ${a.dep_time}`);
                    const dateB = new Date(`${b.dep_date} ${b.dep_time}`);
                    return dateA - dateB;
                case "Price_Highest":
                    return (b.total_price || 0) - (a.total_price || 0);
                case "Price_Lowest":
                    return (a.total_price || 0) - (b.total_price || 0);
                default:
                    return 0;
            }
        });

        // Combine the sorted valid priced flights with the invalid priced flights at the end
        const sortedData = [...validPricedFlights, ...invalidPricedFlights];

        setFilteredData(sortedData);
    };
    console.log(pathType, "pathType");

    return (
        <>
            <Helmet
                htmlAttributes={{ lang: "en" }}
                defaultTitle="Book Shared Private Flights | Fly Katana"
            >
                <meta
                    name="description"
                    content="Fly Katana provides private plane splitting services for a more efficient and cost-effective travel experience. Book your flight now!"
                />
                <link rel="canonical" href="https://www.flykatana.com/"></link>
                <title>Book Shared Private Flights | Fly Katana</title>
            </Helmet>
            {pathType === "book" && (
                <>
                    <Row style={{ marginTop: "-2px" }}>
                        <Col
                            lg={{ size: 12 }}
                            md={{ size: 12 }}
                            sm="12"
                            className="text-center"
                        >
                            <Grid className="imgBackground">
                                <img className="BannerBottom-image" src={BannerBottom} alt="image" />
                                <div className="fullWidthHomeSearchSell m-3">
                                    <Row className={isDesktop ? `home-page-seacrch-section` : ""}>
                                        <Col
                                            lg={{ size: "7", }}
                                            md={{ size: "7", }}
                                            sm="12"
                                        >
                                            <Card style={{ backgroundColor: "#ffffffde" }} className="">
                                                {typeof user.userid !== "undefined" && (
                                                    <Row className="">
                                                        <Col lg="12" md="12" sm="12">
                                                            <PathArea
                                                                pathType={pathType}
                                                                setPathType={setPathType}
                                                                user={user}
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                                <Book pageName="HomePage" IsBarkAir={true} />
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Grid>
                        </Col>
                    </Row>
                </>
            )}
            {pathType === "search" && (
                <>
                    <Grid style={{ marginTop: "-2px" }} className="imgBackground" ref={cardRef}>
                        <img className="BannerBottom-image" src={BannerBottom} alt="image" />
                        <div className="fullWidthHomeSearch HomePage-Bannersection m-3 mb-6">
                            <h1
                                className=""
                                style={{ marginTop: "0.5rem", color: "white" }}
                            >
                                Buy or Sell Seats on Private Planes, Book Empty Legs and Charters
                            </h1>
                            <Row className={isDesktop ? `home-page-seacrch-section` : ""}>
                                <Col
                                    lg={{ size: "8", }}
                                    md={{ size: "10" }}
                                    sm="12"
                                >
                                    <Card
                                        className="pb-3 overflow-visible"
                                        style={{ opacity: "1 !important", backgroundColor: "#ffffffde" }}
                                    >
                                        {/* {typeof user.userid !== "undefined" && ( */}
                                        <Row className="mt-2">
                                            <Col lg="12" md="12" sm="12">
                                                <PathArea
                                                    pathType={pathType}
                                                    user={user}
                                                    setPathType={setPathType}
                                                />
                                            </Col>
                                        </Row>
                                        {/* )} */}
                                        <Search
                                            unregistedFlightsData={unregistedFlightsData}
                                            setFlightData={setFlightData}
                                            setSearchClick={setSearchClick}
                                            IsBarkAir={true}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Grid>
                    <Row>
                        <Col lg="12" md="12" sm="12" className="mb-3"></Col>
                        {searchClick ? (
                            <Col lg="12" md="12" sm="12" className="mb-3" style={{ paddingLeft: isLgScreen ? "5.5rem" : "", paddingRight: isLgScreen ? "5.5rem" : "" }}>
                                <div className="sticky-component" ref={scrollToRef}>
                                    <Box sx={{ bgcolor: "background.paper", display: "flex", justifyContent: "space-between", alignItems: { xs: "flex-start", sm: "center" }, flexDirection: { xs: "column-reverse", sm: "row" } }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            textColor="#FFE600"
                                            aria-label="scrollable auto tabs example"
                                            TabIndicatorProps={{
                                                style: {
                                                    backgroundColor: "#FFE600",
                                                    height: 3,
                                                    textColor: "#FFE600",
                                                },
                                            }}
                                        >
                                            <Tab label="All" />
                                            <Tab label="Seats" />
                                            <Tab label="Charters" />
                                            <Tab label="Interests" />
                                        </Tabs>
                                        <div style={{ marginLeft: "5px", fontSize: "16px", fontWeight: "bold" }}>Filter by
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                className="ml-2"
                                                value={filterBy}
                                                onChange={handleFilterChange}
                                                label="Age"
                                                IconComponent={HiChevronDown}
                                            >
                                                <MenuItem value="Departure_Time">Departure Time</MenuItem>
                                                <MenuItem value="Price_Highest">Price (Highest First)</MenuItem>
                                                <MenuItem value="Price_Lowest">Price (Lowest First)</MenuItem>
                                            </Select></div>
                                    </Box>
                                </div>
                                <FlightList
                                    flightData={filteredData}
                                    //setFlightData={setFilteredData}
                                    page="Home"
                                />
                            </Col>
                        ) : (
                            <>
                                <CardComponent />
                            </>
                        )}
                    </Row>
                </>
            )}
        </>
    );
}

export default BarkAir;
